import { ref, watch } from '@vue/composition-api'
import store from '@/store'
// import store from '@/store'

export default function useTaskHandler(props, emit) {
  // ------------------------------------------------
  // taskLocal
  // ------------------------------------------------
  const taskLocal = ref(JSON.parse(JSON.stringify(props.task.value)))
  const resetTaskLocal = () => {
    taskLocal.value = JSON.parse(JSON.stringify(props.task.value))
  }
  watch(props.task, () => {
    resetTaskLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  // watch(props.isEventHandlerSidebarActive, val => {
  //   // ? Don't reset event till transition is finished
  //   if (!val) {
  //     setTimeout(() => {
  //       clearForm.value()
  //     }, 350)
  //   }
  // })

  const onSubmit = () => {
    const taskData = JSON.parse(JSON.stringify(taskLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.task.value.id) emit('update-task', taskData.value)
    else emit('add-task', taskData.value)

    // Close sidebar
    emit('update:is-task-handler-sidebar-active', false)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // ------------------------------------------------
  // guestOptions
  // ------------------------------------------------

  /* eslint-disable global-require */

  const assigneeOptions = ref([null])
  store
    .dispatch('app/fetchUsers', {
      list_type: 'all',
    })
    .then(response => {
      const { users } = response.data.data
      const arr = []
      users.forEach(val => {
        arr.push({ label: `${val.name} (${val.email})`, value: val.id })
      })

      assigneeOptions.value = arr
    })
    .catch(() => {
    })
  /* eslint-enable global-require */

  const resolveAvatarVariant = priorityLevel => {
    if (priorityLevel === 2) return 'warning'
    if (priorityLevel === 1) return 'success'
    if (priorityLevel === 3) return 'danger'
    return 'primary'
  }

  const priOrityOptions = [
    { label: 'Düşük', value: 1 },
    { label: 'Normal', value: 2 },
    { label: 'Yüksek', value: 3 },
  ]

  return {
    taskLocal,
    resetTaskLocal,

    // UI
    assigneeOptions,
    resolveAvatarVariant,
    priOrityOptions,
    onSubmit,
  }
}
